<template>
  <div class="login-main">
    <b-row>
      <b-col cols="4">
        <div class="modal-login">
          <div class="container-login-img"><LogoEmpresa /></div>
          <transition mode="out-in" name="fade-left" appear>
            <!-- Cadastre-se grátis -->
            <div class="container-senha" key="senha">
              <p>Cadastre uma nova senha</p>
              <inputValidate :erro="recuperarSenhaErro">
                <input
                  type="password"
                  placeholder="Nova senha"
                  v-model.trim="password"
                  @blur="$v.password.$touch()"
                  @keyup.enter="enterParaEnviar"
                />
              </inputValidate>
              <b-button block variant="success" class="btn-enviar" @click="enviarNovaSenha" :disabled="$v.password.$invalid">
                Atualizar senha
              </b-button>
              <div class="login-links">
                <a class="esqueceu-senha" @click="login">Acessar minha conta</a>
              </div>
            </div>
          </transition>
        </div>
      </b-col>
      <b-col cols="8" class="colored_side"></b-col>
    </b-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { recuperarSenhaErro } from "@/plugins/vuelidate/login";
import * as api from "@/api/login";
import inputValidate from "@/components/formulario/InputValidate.vue";
import LogoEmpresa from "@/components/logos/LogoPositivo.vue";
export default {
  name: "login",
  components: {
    inputValidate,
    LogoEmpresa,
  },
  data() {
    return {
      password: "",
      token: null,
    };
  },
  validations: {
    password: {
      required,
    },
  },
  computed: {
    recuperarSenhaErro,
  },
  methods: {
    enviarNovaSenha() {
      api.enviarNovaSenha({
        token: this.token,
        password: this.password,
      });
    },
    login() {
      this.$router.push({ path: "/login" });
    },
    enterParaEnviar() {
      this.$v.password.$invalid ? false : this.enviarNovaSenha();
    },
  },
  mounted() {
    this.token = this.$route.params.token;
  },
};
</script>

<style lang="scss" scoped>
.login-main {
  min-height: 100vh;
  padding: 0;
  background-color: #ffffff;
  // background-color: radial-gradient(ellipse farthest-corner at 50% 50%, $degrade-login-claro 0%, $degrade-login-escuro 100%);
  @media (max-width: 700px) {
    // background: radial-gradient(ellipse farthest-corner at 50% 50%, $degrade-login-claro 0%, $degrade-login-escuro 120%);
    background: #ffffff;
  }
}
.colored_side {
  @include gradientRight;
  min-height: 100%;
}
.modal-login {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 0 50px;
  max-width: 400px;
  margin: 0 auto;
  // float: right;
  width: 100%;
}
.login-links {
  padding: 24px;
  text-align: center;

  a {
    display: block;
    cursor: pointer;
    text-decoration: underline;
    color: $textos-login;
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 400;
  }
}
.container-login-img {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 35%;
  img {
    width: 100%;
    max-width: 450px;
    height: auto;
    max-height: 250px;
    pointer-events: none;
    @media (max-width: 700px) {
      max-height: 150px;
    }
  }
}
.container-login,
.container-senha {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 250px;
  > div {
    width: 100%;
  }
  ul {
    color: $dark;
    font-size: 14px;
    margin-bottom: 30px;
    padding-left: 25px;

    li {
      position: relative;

      &:before {
        background: $red;
        content: "";
        height: 3px;
        left: -20px;
        position: absolute;
        top: 10px;
        transform: rotate(45deg);
        width: 5px;
      }

      &:after {
        background: $red;
        content: "";
        height: 3px;
        left: -18px;
        position: absolute;
        top: 9px;
        transform: rotate(-45deg);
        width: 13px;
      }
    }
  }
  p {
    font-size: 22px;
    width: 100%;
    margin: 20px 0 10px;
    color: $textos-login;
  }
}
.btn-enviar {
  background: $red;
  border-color: $red;
  font-weight: 500;
  padding: 10px;
  &:hover {
    background: darken($red, 5%);
    border-color: darken($red, 5%);
  }
  &:disabled,
  &:focus {
    background: $red;
    border-color: $red;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem lighten($red, 20%);
  }
}
.login-copy {
  width: 100%;
  text-align: center;
  p {
    font-size: 18px;
    color: $textos-login;
  }
}
</style>
